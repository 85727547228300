import React from "react"
import Loader from "./Loader";
import {verifyUser} from "../utils/api";
import {Link} from "gatsby";
import Alert from "./Alert";
import {ArrowRightIcon} from "@heroicons/react/outline";
import localization from "../lang";

class Verify extends React.Component {
    state = {
        isLoading: false,
        language: 'en_US',
        email: undefined,
        isVerified: undefined,
        errors: [],
        questionnaires: []
    }

    componentDidMount = () => {
        const {id} = this.props
        let lang = 'en_US'

        if (navigator.language.startsWith('cs') || navigator.language.startsWith('cz')) {
            lang = 'cs_CZ'
        }

        this.setState({
            language: lang
        })

        verifyUser(id, {}, () => {
            this.setState({
                isLoading: true
            })
        }).then(response => {
            const {data} = response.data
            this.setState({
                email: data.email,
                isVerified: data.state === 'verified',
                questionnaires: data.questionnaires
            })
        }).catch(e => {
            const {response} = e
            const {errors} = response.data
            this.setState({
                errors: errors
            })
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const {
            isLoading,
            email,
            isVerified,
            errors,
            questionnaires,
            language,
        } = this.state

        let locale;
        if (localization.hasOwnProperty(language)) {
            locale = localization[language]
        } else {
            locale = localization['en_US']
        }
        const errorsList = []
        for (const e of errors) {
            errorsList.push(
                <li>{e.title}</li>
            )
        }

        let questionnaireLink = null

        if (questionnaires.length > 0) {
            const r = questionnaires[0]
            questionnaireLink = '/r/'+r.path
        }

        return (
            <div className={'min-w-screen min-h-screen bg-gray-900 flex items-center justify-center'}>
                <Loader active={isLoading}
                        title={locale.app.loading.title}
                        message={locale.app.loading.message}/>

                <main
                    className={'container mx-auto bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden mx-5 my-5'}
                    style={{maxWidth: '1000px'}}>
                    <div className={'md:flex w-full'}>
                        <div className={'md:flex flex-wrap content-center w-full md:w-7/12 py-10 px-5 md:px-10'}>
                            {email === undefined &&
                                <Alert type={'info'} message={'Verifying your account...'}/>
                            }
                            {email !== undefined && isVerified === true &&
                            <Alert type={'success'} message={(
                                <div>
                                    <p>
                                        Your email <strong>{email}</strong> has been successfully verified. Please,
                                        follow to the questionnaire.
                                    </p>
                                    <p>
                                        <Link
                                            className={'underline font-semibold'}
                                            to={questionnaireLink}>Open the questionnaire  <ArrowRightIcon className={'h-3 w-3 text-white inline-block'}/></Link>
                                    </p>
                                </div>
                            )}/>
                            }
                            {isVerified === false &&
                            <Alert type={'warning'} message={(
                                <div>
                                    <p>
                                        There has been some problem verifying <strong>{email}</strong>. Please,
                                        contact the support at <strong>Q24@qedgroup.cz</strong>.
                                    </p>
                                </div>
                            )}/>
                            }
                            {errors.length > 0 &&
                            <Alert type={'danger'} message={(
                                <div>
                                    <ul>{errorsList}</ul>
                                </div>
                            )}/>
                            }
                        </div>
                        <div className={'hidden md:flex flex-wrap content-center w-5/12 bg-white py-10 px-10'}>
                            <img src={'/elements.png'} alt={'4Elements Inventory'}/>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default Verify
