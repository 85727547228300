import React from "react"
import {Router} from "@reach/router"
import Layout from "../components/Layout"
import PrivateRoute from "../components/PrivateRoute"
import Verify from "../components/Verify";

const V = () => (
    <Layout>
        <Router>
            <PrivateRoute path="/v/:id" component={Verify}/>
        </Router>
    </Layout>
)

export default V
